<template>
  <div class="line-chart-wrapper trend-industry-line-chart">
    <!-- <div class="line-chart-actions-top spacer" style="height: 24px"></div> -->
    <div class="line-chart-actions-top">
      <button
        class="chart-btn-action btn-prev"
        :disabled="isDisabledPrev"
        @click="prev"
        @mouseover="handleMouseOverPrevBtn"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
        @touchstart="handleTouchStartPrevBtn"
        @touchend="handleTouchEndPrevBtn"
      >
        <img :src="prevIconUrl || prevIcon" alt="Prev" />
      </button>
      <button
        class="chart-btn-action btn-next"
        :disabled="isDisabledNext"
        @click="next"
        @mouseover="handleMouseOverNextBtn"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
        @touchstart="handleTouchStartNextBtn"
        @touchend="handleTouchEndNextBtn"
      >
        <img :src="nextIconUrl || nextIcon" alt="Next" />
      </button>
    </div>

    <div class="chart line-chart line-chart-year chart-scroll">
      <wj-flex-chart
        ref="chart"
        :key="key"
        chartType="LineSymbols"
        :dataLabel="false"
        :itemsSource="data"
        :initialized="onChartInitialized"
        :tooltipContent="customTooltip"
        :rendered="onChartRendered"
        :palette="paletteCurrent"
      >
        <wj-flex-chart-animation
          :initialized="initializeAnimation"
          :easing="easing"
          :duration="500"
        ></wj-flex-chart-animation>
        <wj-flex-chart-series
          v-for="(item, index) in newSeriesConfigurations"
          :key="index"
          :binding="item"
          :name="item"
        ></wj-flex-chart-series>
      </wj-flex-chart>
      <div class="selected-div" :style="selectedDivStyle"></div>
      <div class="hover-div" :style="hoverDivStyle"></div>
    </div>
    <div class="line-chart-actions-bottom">
      <button
        class="chart-btn-action btn-prev"
        :disabled="isDisabledPrev"
        @click="prev"
        @mouseover="handleMouseOverPrevBtn"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
        @touchstart="handleTouchStartPrevBtn"
        @touchend="handleTouchEndPrevBtn"
      >
        <img :src="prevIconUrl || prevIcon" alt="Prev" />
      </button>
      <button
        class="chart-btn-action btn-next"
        :disabled="isDisabledNext"
        @click="next"
        @mouseover="handleMouseOverNextBtn"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
        @touchstart="handleTouchStartNextBtn"
        @touchend="handleTouchEndNextBtn"
      >
        <img :src="nextIconUrl || nextIcon" alt="Next" />
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import '@mescius/wijmo.vue2.chart';
import '@mescius/wijmo.vue2.chart.animation';
import '@mescius/wijmo.styles/wijmo.css';
import { TABLET_WIDTH } from '@/constants/screen-size';
import { formatValue, $_helper_isNumberType, math } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { PALETTE_COLOR } from '@/components/dashboard/home-pcaf/trend-industry-chart/define';
import { getUnitText } from '@/utils/pcaf';
import { prepareChartAxisYLineChart } from '@/concerns/chart';
import { numberFormat } from '@/filters/number';
import { formatValueUnit } from '@/concerns/chart';
import { isTotalEmission } from '@/utils/pcaf';
const MAX_CHART_ITEMS = 6;
const LABEL_PADDING_X = 16;
const LABEL_PADDING_Y = 26;
export default {
  props: {
    durationList: {
      type: Array,
      default: () => [],
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: 0,
    },
    assetsNameTrend: {
      type: Array,
      default: () => [],
    },
    yearsNotRegisterData: {
      type: Array,
      default: () => [],
    },
    assetsTypes: {
      type: Array,
      default: () => [],
    },
    startMonth: {
      type: Number,
      default: 4,
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      easing: 'EaseInQuad',
      animationMode: 'Point',
      min: 0,
      max: 500,
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: true,
      isDisabledNext: false,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      data: [],
      breakDownData: [],
      selectionStart: null,
      selectionEnd: null,
      chart: null,
      selectedDivStyle: {},
      hoverDivStyle: {},
      previousRectElement: null,
      palette: PALETTE_COLOR,
      rawData: [],
      key: 0,
      pageCurrent: 0,
      newSeriesConfigurations: [],
      nextIconUrl: '',
      prevIconUrl: '',
    };
  },
  created() {
    // this.pageCurrent = this.dashboard.emissionsByProducts.selectedData.pageCurrent;
  },
  async mounted() {
    this.statusBtn();
    window.addEventListener('resize', this.handleResize);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    ...mapState('product', ['dashboard', 'colors']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnitState() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    paletteCurrent() {
      return this.assetsTypes.map(item => item.color)
    },
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      const existNegativeNumber = this.chartValue.find(
        (item) => item.emission_scope1 < 0 || item.emission_scope2 < 0 || item.emission_scope3 < 0,
      );
      return existNegativeNumber ? true : false;
    },
    getColor() {
      const productSelect =
        this.productsMasterList.length <= 0 ? this.productMasterDefault.length : this.productsMasterList.length;
      return this.colors.slice(0, productSelect);
    },
  },
  destroyed() {
    this.handleOffEvent();
  },
  methods: {
    ...mapActions('product', ['updateEmissionsByProducts']),

    statusBtn() {
      if (this.pageCurrent === 0) {
        this.isDisabledNext = this.breakDownData.length <= MAX_CHART_ITEMS;
        this.isDisabledPrev = true;
      } else {
        this.isDisabledNext = this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length;
        this.isDisabledPrev = false;
      }
    },
    initializeAnimation(animation) {
      this.chartAnimation = animation;
      this.chartAnimation.animationMode = this.animationMode;
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    prepareChartDataValue() {
      if (this.data.length > 0) {
        let getTotalEmissionsAtIndex0 = [];
        if (this.pageCurrent !== 0) {
          this.data = this.breakDownData.slice();
          let index = this.pageCurrent - 1;

          const dataKeys = Object.keys(this.data[0])
            .map((item) => {
              return item !== 'year' ? item : '';
            })
            .filter((item) => item !== '');
          const mediumScore = dataKeys?.reduce((result, key) => {
            result[key.toString()] = (this.data[index][key] + this.data[index + 1][key]) / 2;
            if ($_helper_isNumberType(key.toString())) {
              getTotalEmissionsAtIndex0.push((this.data[index][key] + this.data[index + 1][key]) / 2);
            }
            return result;
          }, {});

          this.data.splice(0, this.pageCurrent);
          this.data.unshift(mediumScore);

          //get length 5 data chart
          this.data = [...this.data.slice(0, MAX_CHART_ITEMS + 1)];

          this.chart.axisX.min = 0;
          this.chart.axisX.max = MAX_CHART_ITEMS;
        } else {
          this.chart.axisX.min = undefined;
          this.chart.axisX.max = MAX_CHART_ITEMS - 1;

          //get length 4 data chart
          this.data = [...this.breakDownData.slice(0, MAX_CHART_ITEMS)];
        }

        this.addClassNameToRemoveSymbol();
        let totalsEmission = this.data
          .map((item) =>
            Object.keys(item)
              .filter((key) => key.includes('item'))
              .map((key) => item[key]),
          )
          .flat();
        this.updateAxisYChart(totalsEmission);
      }
    },
    addClassNameToRemoveSymbol() {
      if (this.chart) {
        const el = this.$refs.chart.$el;
        if (el.classList.contains('remove-symbol-size')) {
          el.classList.remove('remove-symbol-size');
        }
        if (this.pageCurrent !== 0) {
          if (!el.classList.contains('remove-symbol-size')) {
            el.classList.add('remove-symbol-size');
          }
        }
      }
    },
    handleMouseOverNextBtn() {
      this.isMouseOverNext = true;
      this.nextIconUrl = '';
    },
    handleTouchStartNextBtn() {
      this.prevIconUrl = '';
      this.nextIconUrl = 'img/icons/next-hover.svg';
    },
    handleTouchEndNextBtn() {
      setTimeout(() => {
        if (this.isDisabledNext) {
          this.nextIconUrl = 'img/icons/next-disabled.svg';
        } else {
          this.nextIconUrl = 'img/icons/next.svg';
        }
      }, 200);
    },
    next() {
      this.prevIconUrl = '';
      if (this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
        this.isDisabledPrev = false;
        return;
      }

      this.isDisabledNext = false;
      this.pageCurrent = this.pageCurrent + 1;
      if (this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();
      if (this.pageCurrent > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
      this.chart.axisX.labelPadding = LABEL_PADDING_Y;
      this.chart.refresh();
    },
    handleMouseOverPrevBtn() {
      this.isMouseOverPrev = true;
      this.prevIconUrl = '';
    },
    handleTouchStartPrevBtn() {
      this.nextIconUrl = '';
      this.prevIconUrl = 'img/icons/prev-hover.svg';
    },
    handleTouchEndPrevBtn() {
      setTimeout(() => {
        if (this.isDisabledPrev) {
          this.prevIconUrl = 'img/icons/prev-disabled.svg';
        } else {
          this.prevIconUrl = 'img/icons/prev.svg';
        }
      }, 200);
    },
    prev() {
      this.nextIconUrl = '';
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
        return;
      }
      this.isDisabledPrev = false;
      this.pageCurrent = this.pageCurrent - 1;
      this.prepareChartDataValue();
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.pageCurrent + MAX_CHART_ITEMS < this.breakDownData.length) {
        this.isDisabledNext = false;
      }
      this.chart.axisX.labelPadding = LABEL_PADDING_Y;
      this.chart.refresh();
    },
    transferData(data) {
      this.data = [...data.slice(0, MAX_CHART_ITEMS)];
      // get last page of the chart data
      this.pageCurrent = Math.ceil(this.chartData.length - MAX_CHART_ITEMS);
      this.breakDownData = data;
      this.updateInfoChart();
      this.statusBtn();
      if(data.length) {
        this.newSeriesConfigurations = Object.keys(this.data[0]).filter((item) => item.includes('item'));
          //when update chart data with page current
        this.prepareChartDataValue();
      }
    },
    rotateLabelXAxis() {
      if(this.chart) {
        this.chart.axisX.labelAngle = 0;
        this.chart.axisX.labelPadding = LABEL_PADDING_Y;
      }
    },
    handleResize() {
      this.rotateLabelXAxis()
    },
    onChartInitialized(flexchart) {
      this.chart = flexchart;
      flexchart.bindingX = 'year';
      flexchart.legend.position = 0; // remove chart legend
      flexchart.symbolSize = 8;
      flexchart.axisX.majorTickMarks = false;
      flexchart.axisY.axisLine = true;
      flexchart.axisY.majorGrid = false;
      flexchart.axisX.max = MAX_CHART_ITEMS - 1;
      const chartEl = flexchart.hostElement;
      this.handleEventChart(chartEl);
      flexchart.axisY.labelPadding = LABEL_PADDING_X;
      flexchart.axisX.labelPadding = LABEL_PADDING_Y;
      this.rotateLabelXAxis();
      const el = this.$refs.chart.$el;
      if (this.pageCurrent && this.pageCurrent !== 0) {
        el.classList.add('remove-symbol-size');
      }
      // this.updateInfoChart();
    },
    handleEventChart(chartEl) {
      chartEl.addEventListener('mouseleave', this.mouseLeaveEvent);
      chartEl.addEventListener('click', this.clickEvent);
      chartEl.addEventListener('mousemove', this.hoverEvent);
    },
    handleOffEvent() {
      const chartEl = this.chart.hostElement;

      if (chartEl) {
        chartEl.removeEventListener('mouseleave', this.mouseLeaveEvent);
        chartEl.removeEventListener('click', this.clickEvent);
        chartEl.removeEventListener('mousemove', this.hoverEvent);
      }
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num));
    },
    updateInfoChart() {
      // the "last" MAX_CHART_ITEMS from the chart element
      let totalsEmission = [...this.chartData.slice(-MAX_CHART_ITEMS)]
        .map((item) =>
          Object.keys(item)
            .filter((key) => key.includes('item'))
            .map((key) => item[key]),
        )
        .flat(); //[[1,2],[3,4]] -> [1,2,3,4]

      // chart empty items
      if(totalsEmission.length === 0) {
        totalsEmission = [100, 0];
        const listYear = []
        let currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        if (this.startMonth > currentMonth) {
          currentYear = currentYear - 1;
        }
        const startYear = 2017;
        for (let year = startYear; year <= currentYear; year++) {
          listYear.push({year: year.toString()})
        }
        this.data = [...listYear];
        this.breakDownData = [...listYear];
        this.newSeriesConfigurations = ['newSeries']
      }
      this.updateAxisYChart(totalsEmission);
    },
    onChartRendered(flexchart, args) {
      const isExistRects = flexchart.axisX._rects;
      if (isExistRects) {
        // this.updateSelected();
        this.updateYAxisRange();
        if (this.pageCurrent !== 0) {
          flexchart.axisX.max = MAX_CHART_ITEMS + 0.2;
        } else {
          flexchart.axisX.max = MAX_CHART_ITEMS - 0.8;
        }
      }
    },
    updateIndexSelectedToSore(indexSelected, item) {},
    mouseLeaveEvent(e) {
      this.hoverDivStyle = {
        ...this.hoverDivStyle,
        display: 'none',
      };
    },
    clickEvent(e) {
      if(window.innerWidth >= TABLET_WIDTH) {
        return;
      }
      const hitTestInfo = this.chart.hitTest(e.pageX, e.pageY);
      const selectedItem = hitTestInfo.item;
      selectedItem.nameAssets = hitTestInfo.name.slice(4, hitTestInfo.name.length);
      selectedItem.detail =  hitTestInfo.item['detail'+hitTestInfo.name.slice(4, hitTestInfo.name.length)];
      selectedItem.value = hitTestInfo.value;
      this.$emit('updateSelectedChartData', selectedItem, window.innerWidth < TABLET_WIDTH, true);
    },
    hoverEvent(e) {},
    updateStylesDiv(index) {
      const chart = this.chart;
      const chartRect = chart._rectChart;
      const xAxis = chart.axisX;
      return {
        position: 'absolute',
        bottom: LABEL_PADDING_X - LABEL_PADDING_Y + 'px',
        left: xAxis._rects[index]?.left - 20 + 'px',
        width: xAxis._rects[index]?.width + 20 + 'px',
        height: chartRect.height - 4 + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.1s ease-in-out',
        border: '1px dashed #A9A04B',
      };
    },
    formatNumberCustome(num, isNormal = true) {
      if (num == 0 || !num) {
        return '-';
      }
      const isTco2Unit = this.getUnitState === 't-CO2';
      const value = isTotalEmission(this.type) && isNormal ? formatValueUnit(num, isTco2Unit) : num;
      return addThoundSandCommaWithTwoDigit(value, '', false, true);
    },
    customTooltip(ht) {
      const item = ht.item;
      if (!item.year || window.innerWidth < TABLET_WIDTH) return '';
      let name = ht.name.slice(4, ht.name.length);
      if (name === '') return '';
      const detailName ='detail'+ht.name.slice(4, ht.name.length);
      let valueEmission = item[detailName]?.total_amount || item[detailName]?.total_emission
      return `<div style='display: flex;gap: 4px;flex-direction: column;padding: 4px; min-width: 131px; width: 100%;font-family: 'Source Han Sans';'>
                    <div style="color: #404D50;letter-spacing: 0.03em;line-height: 18px;font-size: 11px;font-weight: 700;">${name}</div>
                    <div  style="display: flex; align-items: stretch; gap: 8px;width: 100%;min-width: 131px;">
                      <div style="display: flex; align-items: flex-start; gap: 4px;flex-direction: column;">
                        <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50; white-space: nowrap;'>${this.typeText}</span>
                        <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50; white-space: nowrap;'>${this.$t("dashboard_main.label_year_on_year_ratio")}</span>
                      </div>
                      <div style="display: flex; align-items: flex-start; gap: 4px;width: 100%;min-width: 68px;flex-direction: column;">
                        <div style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50; white-space: nowrap;'>${this.formatNumberCustome(valueEmission)} ${this.unit()}</div>
                        <span style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50; white-space: nowrap;'>${this.changeYearEveryValue(item, ht?.name)}</span>
                      </div>
                    </div>
                  </div>`;
    },
    changeYearEveryValue(item, name) {
      const detailName ='detail'+name.slice(4, name.length);
      const newItem = item[detailName];
      let ratioValue = newItem?.ratio_amount || newItem?.ratio_emission;
      ratioValue = ratioValue === null || ratioValue === undefined ? '-' : this.formatNumberCustome(ratioValue, false);
      let unit = '';
      if (ratioValue !== '-') {
        unit = '%';
        if (newItem?.up_amount === 1 || newItem?.up_emission === 1) {
          unit += '↑';
        } else {
          unit += '↓';
        }
      }
      return ratioValue + unit;
    },
    unit() {
      return getUnitText(this.type, this.currency, this.getUnitState);
    },
    updateYAxisRange() {},
    addSelectedClass(index) {
      const axisXElement = this.$el.querySelectorAll('.wj-axis-x .wj-label');
      for (let i = 0; i < axisXElement.length; i++) {
        axisXElement[i]?.classList.remove('active-select');
      }
      axisXElement[index]?.classList.add('active-select');
    },
    updateAxisYChart(dataCalc) {
      const calcAxisY = prepareChartAxisYLineChart(dataCalc, 10);
      this.chart.axisY.majorUnit = calcAxisY[2];
      this.chart.axisY.min = calcAxisY[0];
      this.chart.axisY.max = calcAxisY[1];
      this.max = calcAxisY[1];
      this.chart.axisY.itemFormatter = (engine, label) => {
        if (math.largerEq(label.val, this.max)) {
          label.text = this.unit();
          label.cls += ' unit';
        }
        else {
          const isTco2Unit = this.getUnitState === 't-CO2';
          label.text = label.val === 0 ? '0' : isTotalEmission(this.type) ? numberFormat(formatValueUnit(label.val, isTco2Unit)) : numberFormat(label.val);
        }
        return label;
      };
      this.chart.refresh(true);
    },
  },
  watch: {
    tab: {
      handler() {
        this.transferData(this.chartData);
      },
      deep: true,
    },
    chartData: {
      handler() {
        this.transferData(this.chartData);
      },
      deep: true,
    },
    itemsSettings: {
      handler() {
        this.transferData(this.chartData);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';
.line-chart-actions-top {
  margin-bottom: 16px;
}

.line-chart-actions-bottom .btn-next {
  position: absolute;
  right: 27px !important;
  bottom: 17px;
}
.line-chart-actions-bottom .btn-prev {
  position: absolute;
  left: 2px;
  bottom: 17px;
}
.line-chart .wj-flexchart {
  display: flex;
  min-height: calc(459px - 27px - 24px);
  width: 90%;
}

@media only screen and (max-width: 1024px) {
  .line-chart .wj-flexchart {
    width: 100%;
  }
}

</style>
<style lang="scss">
@import '@/components/products/charts/period/line-chart/index.scss';
.line-chart-wrapper {
  padding: 0 16px 16px !important;
}

.trend-industry-line-chart.line-chart-wrapper .line-chart .wj-flexchart .wj-axis-x .wj-label {
  fill: $monoBlack !important;
}
.trend-industry-line-chart {
  .line-chart {
    .wj-flexchart {
      margin-left: -8px;
    }
  }
  .remove-symbol-size .wj-axis-x g:first-child text {
    font-size: 11px !important;
  }
}
</style>
