<template>
  <div class="type-filter">
    <div v-if="isShowTypeFilter" class="tab-filter">
      <div
        v-for="(item, i) in inputTypes"
        :key="i"
        class="tab-item"
        @click="setSelectedType(item.id)"
        :class="{ selected: item.id === selectedType }"
      >
        {{ item.name }}
        <v-tooltip v-if="item.id === 3" max-width="400px" right :nudge-top="44" :nudge-left="8">
          <template v-slot:activator="{ on, attrs }">
            <div class="question" v-bind="attrs" v-on="on">
              <img
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                @click.stop="changeTooltipImage($event)"
                :src="getSettingIcon('helper-icon.svg')"
                alt=""
              />
            </div>
          </template>
          <span v-html="item.tooltip" class="t-label"></span>
        </v-tooltip>
      </div>
    </div>
    <v-radio-group
      v-if="selectedType === 2"
      v-model="currencyType"
      hide-details
      class="type-filter-radio-group select-currency"
    >
      <v-radio :ripple="false" v-for="(item, i) in currencyTypes" :key="i" :label="item.name" :value="item.id" />
    </v-radio-group>
  </div>
</template>
<script>
import { INPUT_TYPES_FILTER } from '@/constants/pcaf';
export default {
  props: {
    type: {
      type: Number,
      default: 1
    },
    currency: {
      type: Number,
      default: 1
    },
    chart: {
      type: String,
      default: ''
    },
  },
  computed: {
    selectedType: {
      get() {
        return this.type;
      },
      set(value) {
        this.$emit('update:type', value, this.inputTypes.find(item => item.id == value)?.name?.replace(/\（JPY\）/g, '').replace(/\（USD\）/g, ''));
      },
    },
    currencyType: {
      get() {
        return this.currency;
      },
      set(value) {
        this.$emit('update:currency', value);
      },
    },
    isShowTypeFilter() {
      return this.chart !== 'quality-scopre';
    }
  },
  data() {
    return {
      inputTypes: INPUT_TYPES_FILTER,
      showTooltip: false,
      currencyTypes: [
        {
          id: 1,
          name: 'JPY',
        },
        {
          id: 2,
          name: 'USD',
        },
        {
          id: 3,
          name: 'EUR',
        }
      ]
    }
  },
  methods: {
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
    setSelectedType(tabId) {
      this.selectedType = tabId;
    },
  }
}
</script>
<style lang="scss" scoped>
.type-filter {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;

  .tab-filter {
    display: flex;
    flex-wrap: wrap;
    background: $monoOffWhite;

    .tab-item {
      width: fit-content;
      padding: 2px 16px;
      min-height: 36px;
      color: $monoBlack;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      display: flex;
      gap: 6px;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        background: $blueLight;
      }
    }
  }
}
</style>
<style lang="scss">
.type-filter {
  .helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: -20px;

    img {
      z-index: 1;
      width: 20px;
      height: 20px;
      background: $bgLight;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      border-radius: 100%;
    }

    &.tooltip {
      display: block;
      justify-content: left;
      background: $monoWhite;
      border-radius: 4px;
      color: $monoBlack;
      margin: 0 auto;
      font-size: 15px;
      padding: 10px;
      height: unset;
      width: 300px;
      max-width: max-content;
      position: absolute;
      right: 0px;
      bottom: 20px;
      z-index: 9999;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
      0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
      0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
  .question {
    position: relative;
    margin-top: 6px;
  }
}
.t-label {
  color: $backDark;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.type-filter-radio-group {
  display: flex;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 4px;
  background: $bgCusLight;
  &.v-input--selection-controls {
    padding-top: 0;
  }
  .v-input__control .v-input__slot .v-radio .v-input--selection-controls__input {
    margin-right: 6px !important;
    margin-top: 3px !important;
  }
  .v-input--radio-group__input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    min-height: 42px;
    padding: 8px 20px;
    border-radius: 4px;
    background-color: rgba(227, 237, 237, 1);
    .v-radio{
      margin: 0 !important;
      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        color: rgba(64, 77, 80, 1);
      }
    }
  }
  .helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: -20px;

    img {
      z-index: 1;
      width: 20px;
      height: 20px;
      background: $bgLight;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.tooltip {
      display: block;
      justify-content: left;
      background: $monoWhite;
      border-radius: 4px;
      color: $monoBlack;
      margin: 0 auto;
      font-size: 15px;
      padding: 10px;
      height: unset;
      width: 300px;
      max-width: max-content;
      position: absolute;
      right: 0px;
      bottom: 20px;
      z-index: 9999;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
      0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
      0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
  &.select-currency {
    .v-input--radio-group__input {
      background-color: unset;
    }
  }
}
@include desktop {
  .type-filter {
    .helper-icon {
      position: relative;
      margin-left: 6px;
      right: unset;
      &.tooltip {
        position: absolute;
        width: 400px;
        max-width: unset;
      }
    }
  }

  .type-filter-radio-group {
    .v-input--radio-group__input {
      gap: 40px;
      padding: 8px 20px;
    }
    .helper-icon {
      position: relative;
      margin-left: 6px;
      right: unset;
      &.tooltip {
        position: absolute;
        width: 400px;
        max-width: unset;
      }
    }
  }
}
</style>